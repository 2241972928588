.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* spinner css */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

/* no internet compoenet  */

.outer_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no_internet_container {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.outer_box .msg_box {
  background: #fff;
  text-align: center;
  max-width: 500px;
  min-height: 200px;
  padding: 20px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.no_internet_container .msg_box {
  max-width: none;
}

.outer_box .msg_box .logo img {
  width: 300px !important;
  height: auto !important;
  border-radius: 0 !important;
  max-width: 300px;
}

.outer_box .msg_box p {
  font-size: 35px;
  font-weight: 500;
  line-height: 45px;
}

.outer_box .msg_box p span {
  font-weight: 700;
  display: block;
}
.outer_box .msg_box {
  background: #fff;
  text-align: center;
  max-width: 500px;
  min-height: 200px;
  padding: 20px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.outer_box .msg_box button {
  margin-top: 50px;
}

button .loading_img {
  display: none;
  width: 25px;
  height: auto;
  margin: 0 auto;
}

.primary_btn {
  background: #00b9b1;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  min-height: 40px;
  width: 100%;
  padding: 0 10px;
  cursor: pointer;
}
